module.exports = {
  siteTitle: "Hi! I'm Justin!",
  siteDescription: `Justin Leong`,
  keyWords: ['Justin', 'Leong', 'software engineer'],
  authorName: 'Justin Leong',
  githubUsername: 'omegaphoenix',
  authorAvatar: '/images/avatar.jpeg',
  // TODO: Update description
  authorDescription: `    I am a software engineer, passionate about learning, optimizing, and compounding. <br />
    In 2012, I started working as an intern at a think tank where <strong>Xubuntu</strong> and <strong>Vim</strong> became my daily drivers. I spent my summers writing software in Python and C++.<br />
    I currently build batch and stream processing platforms and mainly work with <strong>Rust</strong> and <strong>Typescript.</strong>`,
  skills: [
    {
      name: 'Rust',
      level: 50,
    },
    {
      name: 'Typescript',
      level: 60,
    },
    {
      name: 'Elm',
      level: 40,
    },
    {
      name: 'PostgreSQL',
      level: 60,
    },
    {
      name: 'Git',
      level: 70,
    },
    {
      name: 'Docker',
      level: 40,
    },
    /* more skills here */
  ],
  jobs: [
    {
      company: 'Second Spectrum',
      begin: {
        month: 'jan',
        year: '2021',
      },
      duration: null,
      occupation: 'Senior Software Engineer',
      description:
        'Design and implement workflows for sub-second tracking and augmented broadcast feeds for the NBA, EPL, MLS, and ESPN',
    },
    {
      company: 'Second Spectrum',
      begin: {
        month: 'jul',
        year: '2017',
      },
      duration: '2017-2020',
      occupation: 'Software Engineer',
      description:
        'Engineer platforms and libraries for batch and streaming systems for real-time delivery of tracking, analytics, and augmented broadcast feeds for the NBA, Premiere League, and ESPN',
    },
    {
      company: 'Second Spectrum',
      begin: {
        month: 'jun',
        year: '2016',
      },
      duration: '3 months',
      occupation: 'Software Engineering Intern',
      description:
        'Develop datastore backend to perform MapReduce-like operations for efficiently aggregating and querying millions of basketball and soccer markings',
    },
    {
      company: 'EdgeCast, acquired by Verizon',
      begin: {
        month: 'jun',
        year: '2015',
      },
      duration: '3 months',
      occupation: 'Software Engineering Intern',
      description:
        'Implement streaming histogram for anomaly detection to reduce storage space by 90% and CPU utilization by 50% for CDN servers which serve 5-10% of Internet traffic worldwide',
    },
    {
      company: 'Caltech',
      begin: {
        month: 'jun',
        year: '2014',
      },
      duration: '3 months',
      occupation: 'Undergraduate Research Fellow',
      description:
        'Research and development on software for nanofluidic devices for ultra-narrow electrophysiological neural probes for use within the brains of living vertebrates',
    },
    {
      company: 'Caltech',
      begin: {
        month: 'sep',
        year: '2013',
      },
      duration: '4 years',
      occupation: 'Student',
      description: 'Graduated in 2017 with B.S. in Computer Science',
    },
    // http://www.cms.caltech.edu/academics/ugrad_cs
    {
      company: 'Applied Minds',
      begin: {
        month: 'jun',
        year: '2012',
      },
      duration: '2 summers',
      occupation: 'Intern',
      description:
        'Research and development on the user interface and integration software for the navigation system of the KiraVan® Expedition Vehicle',
      // TODO: Add links: https://kiravan.net/gallery/ https://www.wired.com/2014/04/worlds-biggest-rv/
    },
  ],
  // TODO: Update portfolio page
  portifolio: [
    {
      image: '/images/gatsby-starter-cv.png',
      description: 'Gatsby starter CV template',
      url: 'https://www.gatsbyjs.org/starters/santosfrancisco/gatsby-starter-cv/',
    },
    {
      image: '/images/awesome-grid.png',
      description: 'Responsive grid for ReactJS',
      url: 'https://github.com/santosfrancisco/react-awesome-styled-grid',
    },
    /* more portifolio items here */
  ],
  social: {
    linkedin: 'https://linkedin.com/in/justin-leong-780aab102',
    github: 'https://github.com/omegaphoenix',
    email: 'justinleong@secondspectrum.com',
  },
  siteUrl: 'https://justinleong.com',
  pathPrefix: '', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-102040397-9',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: '#000000cc',
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Blog',
      url: 'https://cargopantsprogramming.com',
    },
    /*
    {
      label: 'Projects',
      url: '/portifolio',
    },
    */
  ],
};
